import type { TooltipPlacement } from 'antd/es/tooltip'
import type { FC } from 'react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Button from '@base/buttons/Button'
import MyTripsPopupHOC from '@components/myTrips/MyTripsPopup'
import { UilTicket } from '@iconscout/react-unicons'

type Props = {
  placementMenu?: TooltipPlacement
  showText?: boolean
}

const TicketsButton: FC<Props> = ({ placementMenu, showText = true }) => {
  const { t } = useTranslation()

  return (
    <MyTripsPopupHOC placement={placementMenu}>
      <Button type="link">
        <TicketIcon>
          <UilTicket />
        </TicketIcon>
        {showText && t('tickets')}
      </Button>
    </MyTripsPopupHOC>
  )
}

const TicketIcon = styled.div`
  display: flex;
  margin-inline-end: 12px;

  svg path {
    fill: ${p => p.theme.colors.icon} !important;
  }
`

export default memo(TicketsButton)
